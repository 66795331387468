import { BigNumber } from '@ethersproject/bignumber'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isTenderlyMode } from 'config/constants'
import { AppState } from '../state'
import useCurrentBlockTimestamp from './useCurrentBlockTimestamp'

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): BigNumber | undefined {
  const ttl = useSelector<AppState, number>((state) => state.user.userDeadline)
  // const blockTimestamp = useCurrentBlockTimestamp()
  let currentTimestamp = Math.floor(Date.now() / 1000)
  const blockTimestamp = BigNumber.from(currentTimestamp)
  return useMemo(() => {
    if (isTenderlyMode) return BigNumber.from(2576980379)
    if (blockTimestamp && ttl) return blockTimestamp.add(ttl)
    return undefined
  }, [blockTimestamp, ttl])
}
