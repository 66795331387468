import { Currency, Pair } from '@pancakeswap/sdk'
import {
  Button,
  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  NumericalInput,
  CopyButton,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import styled, { css } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'

import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { formatNumber } from '@pancakeswap/utils/formatBalance'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useAccount } from 'wagmi'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'
import { useRouter } from 'next/router'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  // padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  padding: 0px;
  /* ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      // padding: 8px;
      // background: ${theme.colors.background};
      // border: 1px solid ${theme.colors.cardBorder};
      // border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      // height: auto;
    `}; */
`

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;
  max-width: 100%;
  min-width: 120px;
`
const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
  height: 152px;
  width: 100%;
  padding: 16px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme, error }) => theme.shadows[error ? 'warning' : 'inset']};
  ${({ zapStyle }) =>
    !!zapStyle &&
    css`
      border-radius: 12px;
    `};
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.6;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showBUSD?: boolean
  getInput?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  disabled,
  error,
  showBUSD,
  getInput,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null
  const { pathname } = useRouter()

  const isRemovePage = pathname.includes('remove') ? true : false

  const amountInDollar = useBUSDCurrencyAmount(currency, Number.isFinite(+value) ? +value : undefined)
  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
    />,
  )
  const n = 0.123456789123456789

  return (
    <Box width="100%" position="relative" id={id}>
      <Container as="div" zapStyle={zapStyle} error={error}>
        <Flex height="100%" justifyContent="space-between">
          <Flex height="100%" flexDirection="column" justifyContent="space-between">
            {!isRemovePage ? getInput ? <Text>You get</Text> : <Text>You send</Text> : null}
            {isRemovePage && (
              <Flex
                style={{
                  border: '1px solid #D9D9D91A',
                  background: '#24242B',
                  borderRadius: '8px',
                  padding: '0px 8px',
                }}
              >
                {beforeButton}
                <CurrencySelectButton
                  zapStyle={zapStyle}
                  className="open-currency-select-button"
                  selected={!!currency}
                  onClick={() => {
                    if (!disableCurrencySelect) {
                      onPresentCurrencyModal()
                    }
                  }}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    {pair ? (
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                    ) : currency ? (
                      <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                    ) : null}
                    {pair ? (
                      <Text fontSize="12px" bold id="pair">
                        {pair?.token0.symbol} - {pair?.token1.symbol} LP
                      </Text>
                    ) : (
                      <Text fontSize="12px" bold id="pair">
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                              currency.symbol.length - 5,
                              currency.symbol.length,
                            )}`
                          : currency?.symbol) || t('Select a currency')}
                      </Text>
                    )}
                    {!disableCurrencySelect && <ChevronDownIcon />}
                  </Flex>
                </CurrencySelectButton>
                {/* {token && tokenAddress ? (
                  <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                    <CopyButton width="16px" text={tokenAddress} tooltipMessage={t('Token address copied')} />
                    <AddToWalletButton
                      variant="text"
                      p="0"
                      height="auto"
                      width="fit-content"
                      tokenAddress={tokenAddress}
                      tokenSymbol={token.symbol}
                      tokenDecimals={token.decimals}
                      tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                    />
                  </Flex>
                ) : null} */}
              </Flex>
            )}
            <InputPanel>
              <LabelRow>
                <NumericalInput
                  error={error}
                  disabled={disabled}
                  className="token-amount-input"
                  value={value}
                  onBlur={onInputBlur}
                  onUserInput={(val) => {
                    onUserInput(val)
                  }}
                  align="left"
                />
              </LabelRow>

              <Flex justifyContent="flex-start" mr="1rem">
                <Flex maxWidth="200px">
                  <Text fontSize="14px" color="#CBCBCB">
                    ${formatNumber(amountInDollar ?? 0)}
                  </Text>
                </Flex>
              </Flex>

              {disabled && <Overlay />}
            </InputPanel>
          </Flex>
          <Flex height="100%" justifyContent="space-between" flexDirection="column" alignItems="end">
            {account ? (
              <Text
                onClick={!disabled && onMax}
                color="textSubtle"
                fontSize="12px"
                style={{ display: 'flex', cursor: 'pointer', height: '24px', alignItems: 'center' }}
              >
                {!hideBalance && !!currency
                  ? t('Balance: %balance%', {
                      balance:
                        Number(selectedCurrencyBalance?.toSignificant(9)) == 0
                          ? 0
                          : Number(selectedCurrencyBalance?.toSignificant(9)) > 1
                          ? selectedCurrencyBalance?.toSignificant(9)
                          : selectedCurrencyBalance?.toFixed(6) ?? t('Loading'),
                    })
                  : '—'}
              </Text>
            ) : (
              <Text color="textSubtle" fontSize="12px" style={{ display: 'inline', cursor: 'pointer' }}>
                No token balance
              </Text>
            )}

            <InputRow selected={disableCurrencySelect}>
              {account && currency && selectedCurrencyBalance?.greaterThan(0) && !disabled && label !== 'To' && (
                <Flex alignItems="right" justifyContent="right">
                  {showQuickInputButton &&
                    onPercentInput &&
                    !isMobile &&
                    [25, 50, 75].map((percent) => (
                      <Button
                        key={`btn_quickCurrency${percent}`}
                        onClick={() => {
                          onPercentInput(percent)
                        }}
                        scale="xs"
                        mr="5px"
                        variant="percent"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {percent}%
                      </Button>
                    ))}
                  {showMaxButton && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onMax?.()
                      }}
                      scale="xs"
                      variant="percent"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {t('Max')}
                    </Button>
                  )}
                </Flex>
              )}
            </InputRow>
            {!isRemovePage && (
              <Flex
                style={{
                  border: '1px solid #D9D9D91A',
                  background: '#24242B',
                  borderRadius: '8px',
                  padding: '0px 8px',
                }}
              >
                {beforeButton}
                <CurrencySelectButton
                  zapStyle={zapStyle}
                  className="open-currency-select-button"
                  selected={!!currency}
                  onClick={() => {
                    if (!disableCurrencySelect) {
                      onPresentCurrencyModal()
                    }
                  }}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    {pair ? (
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                    ) : currency ? (
                      <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                    ) : null}
                    {pair ? (
                      <Text fontSize="12px" bold id="pair">
                        {pair?.token0.symbol} - {pair?.token1.symbol} LP
                      </Text>
                    ) : (
                      <Text fontSize="12px" bold id="pair">
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                              currency.symbol.length - 5,
                              currency.symbol.length,
                            )}`
                          : currency?.symbol) || t('Select a currency')}
                      </Text>
                    )}
                    {!disableCurrencySelect && <ChevronDownIcon />}
                  </Flex>
                </CurrencySelectButton>
                {/* {token && tokenAddress ? (
                  <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                    <CopyButton width="16px" text={tokenAddress} tooltipMessage={t('Token address copied')} />
                    <AddToWalletButton
                      variant="text"
                      p="0"
                      height="auto"
                      width="fit-content"
                      tokenAddress={tokenAddress}
                      tokenSymbol={token.symbol}
                      tokenDecimals={token.decimals}
                      tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                    />
                  </Flex>
                ) : null} */}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
