import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 5L12 19" stroke="#60676C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 12L12 19L5 12" stroke="#60676C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Icon;
